<template>
  <div class="lnb">
    <div class="lnbLogo">
      <router-link to="/admin/dashboard">
        <!-- <img src="@/assets/images/logo2.png" alt="로고" /> -->
      </router-link>
    </div>
    <el-row class="tac mainMenu">
      <el-col :span="12">
        <el-menu class="el-menu-vertical-demo" :default-active="navbarState">
          <el-menu-item index="0">
            <router-link to="/admin/dashboard">
              <span>
                <img
                  src="@/assets/images/icon_svg/LNB_dashboard.svg"
                  alt="icon"
                  class="normal"
                />
                대시보드
              </span>
            </router-link>
          </el-menu-item>
          <el-menu-item index="1">
            <router-link to="/admin/users?page=1">
              <span>
                <img
                  src="@/assets/images/icon_svg/LNB_group.svg"
                  alt="icon"
                  class="normal"
                />회원 관리
              </span>
            </router-link>
          </el-menu-item>

          <el-menu-item index="2">
            <router-link to="/admin/word/manage">
              <span>
                <img
                  src="@/assets/images/icon_svg/LNB_word.svg"
                  alt="icon"
                  class="normal"
                />
                단어 관리
              </span>
            </router-link>
          </el-menu-item>
          <el-menu-item index="3">
            <router-link to="/admin/sentence/manage">
              <span>
                <img
                  src="@/assets/images/icon_svg/LNB_sentence.svg"
                  alt="icon"
                  class="normal"
                />
                문장 관리
              </span>
            </router-link>
          </el-menu-item>
          <el-menu-item index="99">
            <router-link to @click.native="logout">
              <span>
                <img
                  src="@/assets/images/icon_svg/icn-logout.svg"
                  alt="icon"
                  class="normal"
                />
                로그아웃
              </span>
            </router-link>
          </el-menu-item>
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      isActive: false,
      isDropped: false,
    };
  },
  computed: {
    ...mapState(["navbarState", "certifyRequestCount"]),
  },

  methods: {
    logout() {
      let result = confirm("로그아웃 하시겠습니까?");
      if (result == false) {
        return;
      }
      this.$store.dispatch("LOGOUT");
    },
  },
};
</script>
